const backendBase = 'https://api-blocky.vaclav.sk';

module.exports = {
  logIn: (type, token) => fetch(`${backendBase}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      type, token,
    }),
  }),

  get: (path) => fetch(`${backendBase}${path}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
  }),

  post: (path, data) => fetch(`${backendBase}${path}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    },
    body: JSON.stringify(data),
  }),
};
