import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { get } from '../helpers/client';
import '../styles/Dashboard.css';

import Menu from './Menu';
import Home from './Home';
import List from './List';
import AccountSettings from './AccountSettings';
import Receipt from './Receipt';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    this.getUserData();
    this.getReceiptData();
  }

  getUserData = async () => {
    const { userData } = this.state;
    if (userData) return userData;
    const request = await get('/user');
    const userDataResponse = await request.json();
    this.setState({ userData: userDataResponse });

    return userDataResponse;
  }

  getReceiptData = async () => {
    const { receiptData } = this.state;
    if (receiptData) return receiptData;
    const request = await get('/receipts/all');
    const receiptDataResponse = await request.json();
    this.setState({ receiptData: receiptDataResponse });
    return receiptDataResponse;
  }

  render() {
    const { userData, receiptData } = this.state;
    return (
      <div className="dashboard-view">
        <Menu userData={userData} />
        <div className="content">
          <Route exact path="/" component={(props) => <Home receiptData={receiptData} history={props.history} />} />
          <Route path="/list" component={(props) => <List receiptData={receiptData} history={props.history} />} />
          <Route path="/account" component={AccountSettings} />
          <Route path="/receipt/:id" component={Receipt} />
        </div>
      </div>
    );
  }
}

export default Dashboard;
