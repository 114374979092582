import React, { Component } from 'react';
import './styles/App.css';
import { GoogleLogin } from 'react-google-login';
import PulseLoader from 'react-spinners/PulseLoader';
import AppleLogin from 'react-apple-login';
import { logIn } from './helpers/client';
// import EmailLogin from './EmailLogin';

class LoginScreen extends Component {
  hasAppleToken = false;

  constructor(props) {
    super(props);
    this.state = {
      loginInProgress: this.checkAppleToken(),
    };

    this.googleClientId = '455149055369-crdgqagi6ts34udsenguju9dgo5ka2ot.apps.googleusercontent.com';
    this.appleClientId = 'sk.vaclav.blocky-web';
    this.getLoginState = props.getLoginState;
  }

  checkAppleToken = () => {
    const appleIdToken = new URLSearchParams(document.location.hash.substr(1)).get('apple_id');
    if (appleIdToken || this.hasAppleToken) {
      this.attemptAppleLogin(appleIdToken);
      return true;
    } return false;
  }

  googleLoginSuccess = async ({ accessToken }) => {
    const request = await logIn('GOOGLE', accessToken);
    const result = await request.json();

    if (result.success) {
      localStorage.setItem('accessToken', result.token);
      this.getLoginState();
      window.location.reload();
    } else alert(`Error: ${result.message}`);
    this.setState({ loginInProgress: false });
  }

  googleLoginError = () => {
    this.setState({ loginInProgress: false });

    // alert('An error occurred whilst logging in')
  }

  attemptAppleLogin = async (idToken) => {
    window.location.hash = '#';
    this.hasAppleToken = true;
    const request = await logIn('APPLE-WEB', idToken);
    const response = await request.json();

    if (response.success) {
      localStorage.setItem('accessToken', response.token);
      this.getLoginState();
      window.location.reload();
    } else alert(`Error: ${response.message}`);
    this.setState({ loginInProgress: false });
  }

  render() {
    const { loginInProgress } = this.state;
    return (
      <div className="App">
        <div className="App-shadow">
          <h1>Bločky</h1>
          { loginInProgress
            ? (
              <PulseLoader
                className="login-loader"
                size={10}
                color="#ffffff"
                loading={loginInProgress}
              />
            )
            : (
              <div className="login-btns">
                <GoogleLogin
                  clientId={this.googleClientId}
                  buttonText="Log in with Google"
                  onRequest={() => this.setState({ loginInProgress: true })}
                  onSuccess={this.googleLoginSuccess}
                  onFailure={this.googleLoginError}
                  cookiePolicy="single_host_origin"
                />
                <AppleLogin
                  clientId={this.appleClientId}
                  scope="name email"
                  responseType="code id_token"
                  responseMode="form_post"
                  redirectURI="https://api-blocky.vaclav.sk/login/apple"
                  designProp={{
                    scale: 2,
                    locale: 'sk_SK'
                  }}
                />
              </div>
            )}
        </div>
      </div>
    );
  }
}

export default LoginScreen;
