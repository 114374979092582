import React, { Component } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import '../styles/Menu.css';
import { PersonCircle } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { logOut } from '../helpers/auth';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = { };
  }

  render() {
    return (
      <Navbar bg="dark" variant="dark">
        <Navbar.Brand>
          <Link to="/">Bločky</Link>
        </Navbar.Brand>
        <Nav className="mr-auto">
          <Nav.Link as={Link} to="/">Prehľad</Nav.Link>
          <Nav.Link as={Link} to="/list">Zoznam</Nav.Link>
        </Nav>
        <div>
          <NavDropdown
            title={(
              <span>
                <PersonCircle id="personIcon" />
                <span id="personName">{this?.props?.userData?.fullName || ''}</span>
              </span>
          )}
            id="accountControls"
          >
            <NavDropdown.Item as={Link} to="/account">Account</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={logOut}>Log out</NavDropdown.Item>
          </NavDropdown>
        </div>
      </Navbar>
    );
  }
}

export default Dashboard;
