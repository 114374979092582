import React, { Component } from 'react';
import { HashRouter } from 'react-router-dom';
import './styles/App.css';
import LoginScreen from './LoginScreen';
import Dashboard from './Dashboard/Dashboard';
import 'bootstrap/dist/css/bootstrap.min.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: this.getLoginState(),
    };
  }

  componentDidMount() {
    this.getLoginState();
  }

  getLoginState() {
    const token = localStorage.getItem('accessToken');
    if (token) {
      this.setState({ isLoggedIn: true });
    }
  }

  render() {
    const { isLoggedIn } = this.state;
    return (
      <div className="app">
        { isLoggedIn
          ? (
            <HashRouter>
              <Dashboard />
            </HashRouter>
          )
          : <LoginScreen getLoginState={this.getLoginState} />}
      </div>
    );
  }
}

export default App;
