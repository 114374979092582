import React, { Component } from 'react';
import Geocode from 'react-geocode';
import { get } from '../helpers/client';
import Format from '../helpers/format';
import '../styles/Receipt.css';

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey('AIzaSyBVpHimCBD7m3JPcy1ivYaqrGrc2YHX4k0');
Geocode.setLanguage('sk');
Geocode.setRegion('sk');
Geocode.enableDebug();


export default class Receipt extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.mapboxAccessToken = 'pk.eyJ1IjoianZ4IiwiYSI6ImNraW9lMm9uMzFhZHoydXA5NGlyMTZ5amYifQ.rW96Yd67xRH5nFTYdijTtw';
  }

  componentDidMount() {
    const id = this.props?.match?.params?.id;
    get(`/receipts/${id}`).then((a) => a.json())
      .then(({ receiptData }) => {
        const address = receiptData.unit.unitType !== 'PORTABLE'
          ? Format.generateFullAddress(receiptData.unit)
          : Format.generateFullAddress(receiptData.organization);
        Geocode.fromAddress(`${receiptData.organization.name}, ${address}`).then((response) => {
          const { lat, lng } = response.results[0].geometry.location;
          this.setState({ lat, lng });
        });
        this.setState({ receiptData });
      });
  }

  getMapboxStaticURL(coordinates) {
    return `https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/static/pin-s+ff0000(${coordinates.join(',')})/${coordinates.join(',')},15/800x200@2x?access_token=${this.mapboxAccessToken}`;
  }

  displayRawData = () => {
    const { receiptData } = this.state;
    const rawJSON = JSON.stringify(receiptData, null, 2);
    const pre = document.createElement('pre');
    pre.innerText = rawJSON;
    const w = window.open();
    w.document.open();
    w.document.write(`<html><body><pre>${pre.innerHTML}</pre>`);
    w.document.close();
  }

  render() {
    const { receiptData, lat, lng } = this.state;
    if (!receiptData) {
      return (<div />);
    }

    return (
      <div>
        <div className="receipt">
          <div className="namePrice">
            <h1>{ receiptData.organization.name }</h1>
            <h2>{ Format.formatPrice(receiptData.totalPrice) }</h2>
          </div>
          <p>
            { receiptData.unit.unitType !== 'PORTABLE'
              ? Format.generateFullAddress(receiptData.unit)
              : `Pokladnica nemá pridelenú adresu (je prenosná). Adresa organizácie je: ${Format.generateFullAddress(receiptData.organization)}`}
          </p>
          { lat && lng && (
          <img
            className="map-preview"
            src={this.getMapboxStaticURL([lng, lat])}
            alt="Mapa"
          />
          )}
          <table>
            <thead>
              <tr>
                <th>Názov</th>
                <th>Množstvo</th>
                <th>Sadzba DPH</th>
                <th>Cena s DPH</th>
              </tr>
            </thead>
            <tbody>

              {
        receiptData && receiptData.items.map((item) => (
          <tr key={`${item.name}`}>
            <td>{ item.name }</td>
            <td>{ Math.round(item.quantity * 100) / 100 }</td>
            <td>{`${Math.round(item.vatRate)} %`}</td>
            <td>{ Format.formatPrice(item.price)}</td>
          </tr>
        ))
      }
            </tbody>
          </table>
          <hr />
          <small>
            <span>
              Dátum nákupu: { receiptData.issueDate } {' '} | {' '}
            </span>
            <span>
              IČO: { receiptData.ico } {' '} | {' '}
            </span>
            <span>
              DIČ: { receiptData.dic } {' '} | {' '}
            </span>
            <span>
              UID: { receiptData.receiptId }
            </span>
            {' '}
            <br />
            <button type="button" className="rawData" onClick={this.displayRawData}>Zobraziť surové dáta</button>
          </small>
        </div>
      </div>
    );
  }
}
