import React, { Component } from 'react';
import '../styles/List.css';
import Format from '../helpers/format';

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  openReceipt(id) {
    const { history } = this.props;
    history.push(`/receipt/${id}`);
  }

  render() {
    const { receiptData } = this.props;
    return (
      <div>
        <table className="list-table">
          <thead>
            <tr>
              <th>Názov podniku</th>
              <th>Adresa</th>
              <th>Dátum vydania</th>
              <th>Cena</th>
            </tr>
          </thead>
          <tbody>
            { receiptData && receiptData.map((receipt) => (
              <tr key={receipt.receiptId} onClick={() => this.openReceipt(receipt.receiptId)}>
                <td>{ receipt.organization.name }</td>
                <td>{ receipt.unit.streetName && `${receipt.unit.streetName}, ${receipt.unit.municipality}`}</td>
                <td>{ receipt.issueDate }</td>
                <td>{ Format.formatPrice(receipt.totalPrice) }</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
