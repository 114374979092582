import React, { Component } from 'react';
import { get, post } from '../helpers/client';

export default class AccountSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailVerfied: false,
      fullName: '',
    };
  }

  setName = () => {
    return post('/user/setName', {
      name: this.state.fullName
    }).then(a => {
      if(a.status === 204) {
        alert('Vaše meno bolo zmenené úspešne');
        window.location.reload();
      } else alert('Zmena mena zlyhala');
    })
  }

  setEmail = () => {
    return post('/user/setEmail', {
      email: this.state.email
    }).then(a => {
      if(a.status === 204) {
        alert('Váš email bol úspešne zmenený.');
        window.location.reload();
      } else alert('Zmena emailu zlyhala');
    })
  }

  handleNameChange = (event) => {
    this.setState({fullName: event.target.value});
  }

  handleEmailChange = (event) => {
    this.setState({email: event.target.value});
  }

  requestVerification = () => {
    return post('/user/requestVerification').then(a => {
      if(a.status === 204) {
        return alert('Overovací email bol odoslaný, skontrolujte svoj email');
      } else alert('Overenie emailu zlyhalo')
    })
  }

  componentDidMount() {
    return get('/user').then(a => a.json())
    .then(({ email, emailVerified, fullName }) => this.setState({ email, emailVerified, fullName }));
  }

  render() {
    return (
      <div>
        <h1>Nastavenia účtu</h1>
        <div className="item">
          <h3>Meno a priezvisko</h3>
          <input
            type="text"
            onChange={this.handleNameChange}
            placeholder={this.state.fullName}
          />
          <button onClick={this.setName}>Uložiť</button>
        </div>

        <div className="item">
          <h3>Email</h3>
          <input
            type="email"
            onChange={this.handleEmailChange}
            placeholder={this.state.email}
          />
          <button onClick={this.setEmail}>Uložiť</button>
          {
            this.state.emailVerified === false ? (<div>
              Váš e-mail nie je overený!
              <button onClick={this.requestVerification}>Overiť e-mail</button>
            </div>) : (<span><span role="img" aria-label="Email overený">✅</span> Email overený</span>)
          }
        </div>
      </div>
    );
  }
}
