import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import '../styles/Home.css';
import md5 from 'md5';
import Format from '../helpers/format';

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.chartReference = React.createRef();
  }

  generateDoughnutData = () => {
    const { receiptData } = this.props;
    if (!receiptData) return {};
    const orgs = [...new Set(receiptData.map((a) => a.organization.name))];
    const totals = [];

    receiptData.forEach((receipt) => {
      const index = orgs.findIndex((name) => name === receipt.organization.name);
      if (!totals[index]) {
        totals[index] = {
          name: receipt.organization.name,
          total: receipt.totalPrice,
        };
      } else totals[index].total += receipt.totalPrice;
    });

    const totalCombined = totals.length > 0
      ? totals.map((a) => a.total).reduce((a, b) => a + b)
      : 0;

    const listedCompanies = totals.filter((a) => a.total / totalCombined > 0.05);
    const listedTotal = listedCompanies.length > 0
      ? listedCompanies.map((a) => a.total).reduce((a, b) => a + b) : 0;
    const othersSum = totalCombined - listedTotal;
    const companyNames = [...listedCompanies.map((a) => a.name), 'Ostatné'];
    const companyPaid = [...listedCompanies.map((a) => a.total), othersSum];
    const colors = companyNames.map((name) => `#${md5(name).substr(0, 6)}`);

    return {
      labels: companyNames,
      datasets: [{
        data: companyPaid,
        backgroundColor: colors,
      }],
    };
  }

  openReceipt(id) {
    const { history } = this.props;
    history.push(`/receipt/${id}`);
  }

  render() {
    const { receiptData } = this.props;

    return (
      <div className="home-screen">
        <div className="item">
          <div className="title">Koláčový graf</div>
          <Doughnut
            ref={this.chartReference}
            data={this.generateDoughnutData()}
            options={{
              legend: {
                labels: {
                  fontColor: 'white',
                },
              },
            }}
          />
        </div>
        <div className="item">
          <div className="title">Posledné nákupy</div>
          <table className="list-table">
            <tbody>
              { receiptData
              && receiptData.length > 0
              && receiptData
                .sort((a, b) => Format.convertDateToObject(b.issueDate)
                - Format.convertDateToObject(a.issueDate))
                .filter((_, i) => i < 5)
                .map((receipt) => (
                  <tr key={receipt.receiptId} onClick={() => this.openReceipt(receipt.receiptId)}>
                    <td>{ receipt.organization.name }</td>
                    <td>{ receipt.issueDate }</td>
                    <td>{ Format.formatPrice(receipt.totalPrice) }</td>
                  </tr>
                ))
          }
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
