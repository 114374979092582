class Format {
  static round(n) {
    return Math.round(n * 100) / 100;
  }

  static generateFullAddress(unit) {
    if (unit.unitType === 'PORTABLE' && !unit.streetName) return null;
    if (!unit.streetName) return 'N/A';
    let fullAddress = unit.streetName;
    fullAddress += ' ';
    if (unit.propertyRegistrationNumber && unit.buildingNumber) {
      fullAddress += `${unit.propertyRegistrationNumber}/${unit.buildingNumber}`;
    } else if (unit.propertyRegistrationNumber) {
      fullAddress += unit.propertyRegistrationNumber;
    } else if (unit.buildingNumber) {
      fullAddress += unit.buildingNumber;
    }

    fullAddress += ', ';
    fullAddress += unit.postalCode.match(/.{1,3}/g).join(' ');
    fullAddress += ' ';
    fullAddress += unit.municipality;
    /* fullAddress += ', ';
        fullAddress += unit.country; */
    return fullAddress;
  }

  static formatPrice(price) { // <- float
    const roundedPrice = (Math.round(parseFloat(price) * 100) / 100).toFixed(2);
    const [euros, cents] = roundedPrice.split('.');
    const priceStr = `${euros},${String(cents).padStart(2, 0)} €`;

    // Regex, kt. pridá medzery na rozdelenie väčších čísel (napr. 100000 € -> 100 000 €)
    // https://stackoverflow.com/a/16637170
    return priceStr.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  static convertDateToObject(dateStr) {
    const [date, time] = dateStr.split(' ');
    const [day, month, year] = date.split('.');
    // const [ hours, minutes, seconds ] = time.split(':')
    return new Date(`${month}/${day}/${year}, ${time}`);
  }
}

export default Format;
